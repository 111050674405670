import React, { HtmlHTMLAttributes, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import HideIcon from 'components/ui/atoms/icons/new/Hide';
import ShowIcon from 'components/ui/atoms/icons/new/Show';
import Tooltip from 'components/ui/atoms/tooltip/Tooltip';
import ButtonNonUi from 'components/ui/atoms/button/ButtonNonUi';
import CopyIcon from 'components/ui/atoms/icons/new/Copy';
import Flex from 'components/ui/atoms/flex/Flex';
import classes from 'components/ui/atoms/typography/Paragraph.module.scss';

import useFlag from 'utils/hooks/useFlag';
import { ns } from 'utils/locales';
import { copyText } from 'utils/copy';
import { sleep } from 'utils/asyncHelpers';

export type TParagraphColor = 'primary' | 'secondary' | 'accent' | 'success' | 'error';

type Props = Omit<HtmlHTMLAttributes<HTMLParagraphElement>, 'onBlur'> & {
  variant?: 'body' | 'footnote-large' | 'footnote-small',
  weight?: 'normal' | 'medium' | 'bold',
  color?: TParagraphColor,
  as?: 'span' | 'p',
  copyable?: boolean;
  hideable?: boolean;
  onBlur?: (text: string) => void;
};

export default function Paragraph(props: Props) {
  const {
    variant = 'body',
    weight = 'normal',
    color,
    as = 'p',
    copyable,
    hideable,
    onBlur,
    children,
    className,
    ...rest
  } = props;

  const classNameString = clsx(classes.paragraph, {
    [classes.paragraph_weight_medium]: weight === 'medium',
    [classes.paragraph_weight_bold]: weight === 'bold',
    [classes.paragraph_variant_footnote_large]: variant === 'footnote-large',
    [classes.paragraph_variant_footnote_small]: variant === 'footnote-small',
    [classes.paragraph_color_secondary]: color === 'secondary',
    [classes.paragraph_color_accent]: color === 'accent',
    [classes.paragraph_color_success]: color === 'success',
    [classes.paragraph_color_error]: color === 'error',
  }, className);

  const Tag = as;

  const { t } = useTranslation();
  const [isOpenCopyTooltip, setOpenCopyTooltip] = useState(false);
  const paragraphRef = useRef<HTMLParagraphElement>(null);

  const { flag: hidden, toggle: toggleHidden } = useFlag({ defaultValue: hideable });

  const handleCopy = async () => {
    setOpenCopyTooltip(true);
    await copyText(paragraphRef.current?.textContent ?? '');
    await sleep(1000);
    setOpenCopyTooltip(false);
  };

  const renderContent = () => (
    <Tag
      {...rest}
      className={clsx({
        [classes.hidden]: hidden,
      }, classNameString)}
      ref={paragraphRef}
      onBlur={() => {
        onBlur?.(paragraphRef.current?.innerText ?? '');
      }}
    >
      {children}
    </Tag>
  );

  if (copyable || hideable) {
    return (
      <Flex align="center" gap={4}>
        {renderContent()}
        {copyable && (
          <Tooltip open={isOpenCopyTooltip} title={<Paragraph>{t(`${ns.tooltips}:copied`)}</Paragraph>}>
            <ButtonNonUi className={classes.paragraph_button} onClick={handleCopy}>
              <CopyIcon />
            </ButtonNonUi>
          </Tooltip>
        )}
        {hideable && (
          <ButtonNonUi className={classes.paragraph_button} onClick={toggleHidden}>
            {hidden ? <HideIcon /> : <ShowIcon />}
          </ButtonNonUi>
        )}
      </Flex>
    );
  }

  return renderContent();
}
